
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'scheduling'
})
export default class BraceletList extends Vue {
  private activeName ='manage'

  created () {
    const url = this.$route.path
    const start = url.lastIndexOf('/')
    this.activeName = url.substring(start + 1)
  }

  handleChange () {
    this.$router.replace({ path: '/schedule/' + this.activeName })
  }
}
